/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.blue.css';
import './css/custom.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './Header';
import Homepage from './Homepage';
import About from './About';
import Projects from './Projects';
import Products from './Products';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import Footer from './Footer';
import PageNotFound from './PageNotFound';
import SunSynkApprovedPage from './SunSynkApprovedPage';
import RentalSolarPackages from './RentalSolarPackages';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
      */}
      <Routes>
        <Route path='/' element={ <Homepage /> } />
        <Route path='/index.html' element={ <Homepage /> } />
        <Route path='about.html' element={ <About /> } />
        <Route path='projects.html' element={ <Projects /> } />
        {/* <Route path='products.html' element={ <Products /> } /> */}
        <Route path='rental-solar-packages.html' element={ <RentalSolarPackages /> } />
        <Route path='contact.html' element={ <Contact /> } />
        <Route path="privacy-policy.html" element={ <PrivacyPolicy /> } />
        <Route path="sunsynk-approved-installer.html" element={<SunSynkApprovedPage /> } />
        <Route path="*" element={ <PageNotFound /> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);
