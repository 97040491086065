/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import heroImage4 from './images/hero-4.jpeg';
import heroImage5 from './images/hero-5.jpeg';
import projectImage1 from './images/proj-1.jpg';
import projectImage2 from './images/proj-2.jpg';
import projectImage3 from './images/proj-3.jpg';
import projectImage4 from './images/proj-4.jpg';
import projectImage5 from './images/proj-5.jpg';
import projectImage6 from './images/proj-6.jpg';
import projectImage7 from './images/proj-7.jpg';
import projectImage8 from './images/proj-8.jpg';
import projectImage9 from './images/proj-9.jpg';
import projectImage10 from './images/proj-10.jpg';
import projectImage11 from './images/proj-11.jpg';
import projectImage12 from './images/proj-12.jpg';
import projectImage13 from './images/proj-13.jpg';
import projectImage14 from './images/proj-14.jpg';
import projectImage15 from './images/proj-15.jpg';
import projectImage16 from './images/proj-16.jpg';
import projectImage17 from './images/proj-17.jpg';
import projectImage18 from './images/proj-18.jpg';
import projectImage19 from './images/proj-19.jpg';
import projectImage20 from './images/proj-20.jpg';
import { openModal, closeModal } from './js';
import './Projects.css';

const images = [
    projectImage1,
    projectImage2,
    projectImage3,
    projectImage4,
    projectImage5,
    projectImage6,
    projectImage7,
    projectImage8,
    projectImage9,
    projectImage10,
    projectImage11,
    projectImage12,
    projectImage13,
    projectImage14,
    projectImage15,
    projectImage16,
    projectImage17,
    projectImage18,
    projectImage19,
    projectImage20
];

class Projects extends Component {
    render(){
        return (
          <div>
            {/* Hero Slider */}
            <section className="hero bg-cover bg-position py-4" style={{backgroundImage: `url(${heroImage5})`}}>
                <div className="container py-5 index-forward text-white">
                    <h1>Our Projects</h1>
                    {/* <!-- Breadcrumb--> */}
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb bg-none mb-0 p-0">
                            <li className="breadcrumb-item pl-0"><a href="/"> <i className="fas fa-home mr-2"></i>Home</a></li>
                            <li className="breadcrumb-item" aria-current="page">Projects</li>
                        </ol>
                    </nav>
                </div>
            </section>
            {/* Services Section */}
            <section className="py-5">
                <div className="container py-5 text-center">
                    <header className="mb-5">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">
                                <p className="h6 text-uppercase text-primary mb-0">Our Project Gallery</p>
                                <h2>Energy Independence through managed transition from Utility Power to Solar Energy</h2>
                            </div>
                        </div>
                    </header>
                    <div className="row align-items-stretch">
                        <div className="card-columns">
                        {images.map((image, index) => 
                            <div className="project-img" key={`index-${index}`}>
                                <img className="modal-img img-fluid" id={'project-' + index} src={image} onClick={() => { openModal(index) }} />
                                {/* The Modal */}
                                <div id={'modal-' + index} className="modal">

                                    {/* The Close Button */}
                                    <span className="close" onClick={() => { closeModal(index) }} >&times;</span>

                                    {/* Modal Content (The Image) */}
                                    <img className="modal-content" id={'modal-img-' + index} />

                                    {/* Modal Caption (Image Text) */}
                                    <div id={'caption-' + index} className="caption"></div>
                                </div>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </section>
            {/* Divider Section */}
            <section className="bg-cover bg-center" style={{backgroundImage: `url(${heroImage4})`}}>
                <div className="primary-overlay py-5">
                    <div className="overlay-content">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 text-center text-lg-left mb-4 mb-lg-0">
                                    <h2 className="text-white mb-2">
                                    Looking to transition to Solar Energy?
                                    </h2>
                                    <p className="text-white mb-0">Tabothi Energy Solutions Is Here To Help You</p>
                                </div>
                                <div className="col-lg-3 ml-auto text-center text-lg-right"><a className="btn btn-outline-light"
                                    href="contact.html">Contact Us Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          </div>
        );
    }
}

export default Projects;
